<template>
  <v-app></v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Roles from '@/utils/auth/roles';

export default {
  name: "redirect",
  computed: {
    ...mapGetters(["user", "userGroup"]),
  },
  created(){
    // Staff roles
    const {DATA_ENTRY_CLERK, DIRECTOR_ONE, DIRECTOR_TWO, OFFICER_IN_CHARGE, RETENTION_STAFF} = Roles;
    switch(this.userGroup){
      // redirect data-clerk staff to manage batches page
      case DATA_ENTRY_CLERK: {
        this.fetchActiveEnrollees();
        this.$router.push({name: "manage-batches"})
        break
      }
      case DIRECTOR_ONE:{
        this.$router.push({name: "list-approve-batches"})
        break
      }
      case DIRECTOR_TWO: {
        this.$router.push({name: "list-schedule-batches"})
        break
      }
      case OFFICER_IN_CHARGE: {
        this.$router.push({name: "list-vet-batches"})
        break
      }
      case RETENTION_STAFF: {
        this.$router.push({name: "retention"})
        break
      }
      default:{
        this.$router.push({name: 'login'})
        break
      }
    }
  },
  methods: {
    ...mapActions({fetchActiveEnrollees: "enrollmentModule/fetchActiveEnrollees"})
  }
};
</script>

<style>
</style>
